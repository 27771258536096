import { React, Link, useNavigate } from "../../common";
import CustomButton from "../../component/CustomButton";
import { MemberTransaction, PhotoUploader } from "../components";

const SignUpScreen = ({ onClicked }) => {
    const NavigateTo = useNavigate();
    const [received, setReceived] = React.useState(false);

    const OnVerifyClicked = async (e) => {
        setReceived(true);
    };

    const OnSubmitClicked = async (e) => {
        sessionStorage.setItem("user", "Hello, Sign In");
        onClicked("hide");
        NavigateTo("/market");
    };

    return (
        <>
            <div className="auth_container">
                <div id="signup">
                    <div className='authLogoPos sscsbLogo'></div>
                    <div className='authHead'>Create New Account</div>
                    <div className='authSubHead'>We are glad to have you on board!</div>

                    <div className="form-group">
                        <label>Name:</label>
                        <input type="text" required />
                    </div>

                    <div className="form-group">
                        <label>Email:</label>
                        <input type="email" required />
                    </div>

                    <div className="form-group">
                        <label>Phone:</label>
                        <div className="getOtpBtnBlk">
                            <input type="button" className="getOtpBtn" value="Get OTP" />
                        </div>
                        <input type="number" className="no-spinner" required />
                    </div>

                    <div className="form-group">
                        <ul className="otpMsg">
                            <li className="otpMsg">
                                <input type="number" placeholder="OTP" maxLength={4} className="no-spinner" required />
                            </li>
                            <li className="otpMsg">
                                Please authenticate using the OTP sent to the mobile number
                            </li>
                        </ul>
                    </div>
                    <div className="form-group" style={{ display: "none" }}>
                        <input type="button" className="resendOtpBtn" value="Resend OTP" />
                    </div>

                    <div className="authTerms">
                        By creating an account, you're agreeing to our <Link to="/terms">Terms Of Use</Link> and our <Link to="/privacy">Security And Privacy Policy</Link>, which includes using arbitration to resolve claims related to the Telephone Consumer Protection Act.
                    </div>
                    <CustomButton type="submit" value="Sign Up" css="btn100pcRed" />

                    <div className="invalidOTP" style={{ display: "none" }}>Invalid OTP. Please Retry</div>

                    <div className="authText">Already have an account?</div>
                    <button onClick={(e) => onClicked("signin")} className="toggle-button">Sign In</button>
                </div>
            </div>
        </>
    );
};

const SignInScreen = ({ onClicked }) => {
    const [received, setReceived] = React.useState(false);
    const [isSignedIn, setIsSignedIn] = React.useState(false);  // State to track sign-in status

    const OnVerifyAccount = async (e) => {
        setReceived(true);
    };

    const OnSignInClicked = async (e) => {
        sessionStorage.setItem("user", "Hello, Sign In");
        setIsSignedIn(true);  // Set user as signed in when button is clicked
        // onClicked("hide");
    };

    return (
        <>
            <div className="auth_container">
                <div id="signin">
                    {!isSignedIn ? (
                        <>
                            {/* Sign In Form */}
                            <div className='authLogoPos sscsbLogo'></div>

                            <div className='authHead'>Sign In</div>
                            <div className='authSubHead'>Let's login to your account</div>

                            <div className="form-group">
                                <label>Phone:</label>
                                <div className="getOtpBtnBlk">
                                    <input type="button" className="getOtpBtn" value="Get OTP" />
                                </div>
                                <input type="number" maxLength={10} className="no-spinner" required />
                            </div>

                            <div className="form-group">
                                <ul className="otpMsg">
                                    <li className="otpMsg">
                                        <input type="number" placeholder="OTP" maxLength={4} className="no-spinner" required />
                                    </li>
                                    <li className="otpMsg">
                                        Please authenticate using the OTP sent to the mobile number
                                    </li>
                                </ul>
                            </div>

                            <div className="form-group" style={{ display: "none" }}>
                                <input type="button" className="resendOtpBtn" value="Resend OTP" />
                            </div>

                            <CustomButton type="submit" value="Sign In" css="btn100pcRed" onClick={OnSignInClicked} />

                            <div className="invalidOTP" style={{ display: "none" }}>Invalid OTP. Please Retry</div>

                            <div className="authText">Don't have an account?</div>
                            <button onClick={(e) => onClicked("signup")} className="toggle-button">Sign Up!</button>
                        </>
                    ) : (
                        <>
                            {/* Show Welcome Message after Sign In */}
                            <div className='authHead'>Welcome, SSCSB Member</div>
                            <div className="memberCard">
                                <ul className="logoAddressPhoto">
                                    <li className="logoAddressPhoto">
                                        <div className="mcLogoPos sscsbLogo"></div>
                                    </li>
                                    <li className="logoAddressPhoto">
                                        <div className="sscsbName">Sreemanta Sankardeva Cultural Society, Bangalore</div>
                                        <div className="sscsbAdd">Marathahalli, Bengaluru 560037<br />Karnataka </div>
                                    </li>
                                    <li className="logoAddressPhoto">
                                        <div className="profilePhoto">
                                            <PhotoUploader />
                                        </div>
                                    </li>
                                </ul>
                                <div className="memberDetails">
                                    <ul className="memberData">
                                        <li className="memberData">Name</li>
                                        <li className="memberData">SSCSB Member Name</li>
                                        <li className="memberData">Membership No</li>
                                        <li className="memberData">SSCSB001</li>
                                        <li className="memberData">Valid Till</li>
                                        <li className="memberData">20-Sept-2025</li>
                                    </ul>
                                </div>
                                <div className="memberButtonBlk">
                                    <Link to="//memberform"><CustomButton type="submit" value="Upgrade Membership" css="btnRed_S_Bold" /></Link>
                                </div>
                                <div className="memberTranHeading">Transaction Details -</div>
                                <MemberTransaction />
                            </div>
                            <Link to="/"><button className="toggle-button">Logout</button></Link>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const Component = () => {
    const [logintype, setLoginType] = React.useState("signin");
    let closeElm = React.useRef(null);

    React.useEffect(() => {
        setLoginType("signin");
    }, []);

    const OnClicked = (e) => {
        if (e === "hide") {
            closeElm.click();
        } else {
            setLoginType(e);
        }
    };

    return (
        <div className="si_su_Layout">
            <Link
                to="#"
                ref={(input) => (closeElm = input)}
                className="si_su_Close"
                onClick={(e) => window.fnSign(0)}
            ></Link>
            <div className="si_su_ContentArea">
                {logintype === "signup" ? (
                    <SignUpScreen onClicked={(e) => OnClicked(e)} />
                ) : (
                    <SignInScreen onClicked={(e) => OnClicked(e)} />
                )}
            </div>
        </div>
    );
};

export default Component;

