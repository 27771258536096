import React from 'react';
import { Link } from 'react-router-dom';

const SiteDeveloper = () => {
    return (
        <ul className='siteDeveloper'>
            <div className='devHead'>Website developed by:</div>
            <li><Link to="mailto:monangku@gmail.com">Monangku Hazarika</Link></li>
        </ul>
    );
}

export default SiteDeveloper;
