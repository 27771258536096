import React, { useState } from 'react';

const PhotoUploader = ({ label }) => {
    const [photo, setPhoto] = useState(null);

    const handlePhotoChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setPhoto(event.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleDeletePhoto = () => {
        setPhoto(null);
    };

    return (
        <div className="photo-uploader">
            <div className="photo-container">
                {photo ? (
                    <>
                        <img src={photo} alt="Uploaded" className="photo" />
                        <button className="delete-btn" onClick={handleDeletePhoto}>
                            &#10060; {/* Unicode for a cross mark */}
                        </button>
                    </>
                ) : (
                    <>
                        <label htmlFor={`fileInput-${label}`} className="browse-btn">Browse</label>
                        <input
                            type="file"
                            id={`fileInput-${label}`}
                            accept="image/*"
                            onChange={handlePhotoChange}
                            style={{ display: 'none' }}
                        />
                    </>
                )}
            </div>
            <div className="photo-label">{label}</div>
        </div>
    );
};

export default PhotoUploader;
