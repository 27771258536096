import React from 'react';
import { Link } from 'react-router-dom';

const TableComponent = () => {
    const data = [
        { date: '2024-09-22', type: 'Xorai', amount: '₹ 2,500', downloadFile: '../files/receipt.pdf' },
        { date: '2024-09-21', type: 'Membership', amount: '₹ 1,000', downloadFile: '../files/receipt1.pdf' },
        { date: '2024-09-20', type: 'Donation', amount: '₹ 15,000', downloadFile: '../files/receipt2.pdf' },
        { date: '2024-09-19', type: 'Renewal', amount: '₹ 1,000', downloadFile: '../files/receipt3.pdf' },
        { date: '2024-09-18', type: 'Anna Dan', amount: '₹ 7,500', downloadFile: '../files/receipt4.pdf' },
    ];

    return (
        <div className="memberTranHeadTable">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Payment For</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
            </table>

            <div className="memberTranTable">
                <table>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item.type}</td>
                                <td>{item.amount}</td>
                                <td>
                                    <Link to={item.downloadFile} download title="Receipt">
                                        <div className="downloadIcon"></div>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableComponent;
